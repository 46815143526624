import { Accordion, AccordionSummary, AccordionDetails, Divider, Box } from '@mui/material';
import OAChip from '../../../../components/Base/OAChip';
import { ArrowDownSVG } from '../../../../components/Icons';
import { OATypography } from '../../../../components/widgets';

export const CustomAccordion = ({
  label,
  tableObj,
  value,
  callback,
  expand,
  flightType,
  tax,
  taxLabel,
  taxValue,
  taxExpand,
  taxCallback,
  filteredData
}: any) => {
  return (
    <Accordion
      defaultExpanded
      expanded={expand}
      onChange={callback}
      sx={{
        'boxShadow': 'none',
        'margin': '0 !important',
        '&::before': { backgroundColor: 'transparent' }
      }}>
      <AccordionSummary
        expandIcon={<ArrowDownSVG down={true} fill='#011229' />}
        aria-controls='panel1-content'
        id='panel1-header'
        sx={{
          'flexDirection': 'row-reverse',
          'padding': '0 8px', // Adjust padding as needed
          'minHeight': '32px', // Adjust minHeight as needed
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: '8px'
          },
          '&.Mui-expanded': {
            minHeight: '32px',
            marginTop: '8px',
            padding: '0px 8px 8px 0px!important'
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: '8px',
            padding: '0px !important'
          }
        }}>
        <OATypography variant='body1'>
          {label}
          <OAChip
            label={flightType}
            color='info'
            sx={{ backgroundColor: 'info.light', marginLeft: '8px' }}
          />
        </OATypography>
        <OATypography variant='body1'>{value}</OATypography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0px !important' }}>
        {expand && <Divider />}
        {tableObj?.map((obj: any, index: number) => {
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '16px'
                }}>
                <OATypography variant={obj?.header ? 'body1' : 'body2'}>{obj?.label}</OATypography>
                <OATypography variant='body2'>{obj?.value ?? 'N/A'}</OATypography>
              </Box>
              <Divider />
            </>
          );
        })}
        {tax && (
          <Accordion
            defaultExpanded
            expanded={taxExpand}
            onChange={taxCallback}
            sx={{
              'boxShadow': 'none',
              'margin': '0 !important',
              '&::before': { backgroundColor: 'transparent' }
            }}>
            <AccordionSummary
              expandIcon={<ArrowDownSVG down={true} fill='#011229' />}
              aria-controls='panel1-content'
              id='panel1-header'
              sx={{
                'flexDirection': 'row-reverse',
                'minHeight': '32px', // Adjust minHeight as needed
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginLeft: '8px'
                },
                '&.Mui-expanded': {
                  minHeight: '32px',
                  marginTop: '8px',
                  padding: '0px 8px 8px 0px!important'
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  margin: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginLeft: '8px',
                  padding: '0px !important'
                }
              }}>
              <OATypography variant='body1'>{taxLabel}</OATypography>
              <OATypography variant='body1'>{taxValue}</OATypography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: '8px 0px !important'
              }}>
              {tax && <Divider />}
              {filteredData?.map((obj: any, index: number) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '16px'
                    }}>
                    <OATypography variant={obj?.header ? 'body1' : 'body2'}>
                      {obj?.label}
                    </OATypography>
                    <OATypography variant='body2'>{obj?.value ?? 'N/A'}</OATypography>
                  </Box>
                  <Divider />
                </>
              ))}
            </AccordionDetails>
          </Accordion>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export const CustomTableAccordion = ({
  CustomLabel,
  label,
  tableData,
  value,
  callback,
  expand,
  flightType
}: any) => {
  return (
    <Accordion
      defaultExpanded
      expanded={expand}
      onChange={callback}
      sx={{
        'boxShadow': 'none',
        'margin': '0 !important',
        '&::before': { backgroundColor: 'transparent' }
      }}>
      <AccordionSummary
        expandIcon={<ArrowDownSVG down={true} fill='#011229' />}
        aria-controls='panel1-content'
        id='panel1-header'
        sx={{
          'flexDirection': 'row-reverse',
          'padding': '0 8px', // Adjust padding as needed
          'minHeight': '32px', // Adjust minHeight as needed
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: '8px'
          },
          '&.Mui-expanded': {
            minHeight: '32px',
            marginTop: '8px',
            padding: '0px 8px 8px 0px!important'
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: '8px',
            padding: '0px !important'
          }
        }}>
        {CustomLabel ? (
          <CustomLabel />
        ) : (
          <OATypography variant='body1'>
            {label}
            {flightType && (
              <OAChip
                label={flightType}
                color='info'
                sx={{ backgroundColor: 'info.light', marginLeft: '8px' }}
              />
            )}
          </OATypography>
        )}
        <OATypography variant='body1'>{value}</OATypography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0px !important' }}>
        {expand && <Divider />}
        {tableData?.map((obj: any, index: number) => {
          return (
            index >= 1 &&
            obj && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px'
                  }}>
                  <OATypography variant={obj?.header ? 'body1' : 'body2'}>
                    {obj?.label}
                  </OATypography>
                  <OATypography variant='body2'>{obj?.value ?? 'N/A'}</OATypography>
                </Box>
                <Divider />
              </>
            )
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
